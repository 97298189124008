export const en = {
  email_label: 'Email',
  password_label: 'Password',
  required: 'Required',
  password_strength_validation:
    'Password must contain at least one numeric digit, one special character, one uppercase and one lowercase letter',
  login: 'Login',
  request_reset_link: 'Send reset link',
  request_reset_link_copy:
    "Enter your email address below and we'll immediately send you a link to reset your password.",
  reset_link_sent_copy:
    'We have sent a reset link to the email address you entered. Use the link in the email to reset your password.',
  reset_link_send_again: 'Re-send reset link',
  new_password: 'Your new password',
  confirm_new_password: 'Repeat your new password',
  set_password: 'Set password',
  reset_password_success_copy: 'Your password has been successfully changed!',
  return_to_login: 'Return to login page',
  personal_information: 'Personal information',
  first_name: 'First name',
  last_name: 'Last name',
  street: 'Street',
  number: 'Number',
  box: 'Box',
  city: 'City',
  phone: 'Phone',
  mobile: 'Mobile',
  birth_date: 'Birthday',
  preferred_language: 'Language',
  reset_your_password: 'Reset your password',
  by_using_our_customer_portal:
    'By using our customer portal you agree with our',
  terms_of_use: 'terms of use',
  and_the: 'and our',
  privacy_policy: 'privacy policy',
  save: 'Save',
  no_buildings_yet: 'No properties are linked to your account',
  create_new_search: 'Create new saved search',
  set_preferences_for_newsletters: 'Set newsletter preferences',
  adjust_your_data: 'Update your profile',
  houses: 'Houses',
  my_profile: 'My profile',
  my_newsletters: 'My newsletters',
  my_searches: 'My saved searches',
  make_an_offer: 'Make a bid',
  how_much_do_you_want_to_offer: 'How much do you want to bid?',
  your_offer: 'Your bid',
  service_fee: 'Service costs',
  total_cost: 'Total cost',
  need_help: 'Need help?',
  what_is_subject_of_message: "What's your message subject?",
  send_message: 'Send message',
  i_wish_to_make_an_offer_with: 'I wish to make a bid under',
  conditions_financing: 'conditions of financing',
  who_is_the_buyer: 'Who is the buyer?',
  add_buyer: 'Add buyer',
  buy_with_company: 'I want to buy through a company',
  submit_offer: 'Submit bid',
  i_agree_with_the: 'I agree with the',
  purchase_conditions: 'purchase terms',
  logout_success: 'Logout Successful!',
  success: 'Success',
  you_have_been_logged_out: 'You have been logged out',
  details_submitted_successfully: 'Details have been submitted successfully',
  form_submitted: 'Form Submitted',
  login_success: 'Log in successful',
  failed_to_fetch_profile_data: 'Failed to fetch profile data',
  failed_to_fetch_property_data: 'Failed to fetch property data',
  something_went_wrong: 'Something went wrong, please try again',
  user: 'User',
  welcome: 'Welcome',
  this_field: 'This field',
  required_fields_missing: 'Required fields are missing',
  our_advice_page_link: 'https://dewaele.com/een-bod-uitvoeren',
  privacy_policy_property_link: 'https://www.dewaele.com/en/privacy-policy',
  loading_cities: 'Loading cities',
  no_cities_found: 'Sorry, no cities found.',
  dashboard_properties_text:
    'Here you can find an overview of all properties that you have managed at Dewaele. This overview also shows properties that meet your search criteria',
  suspensive_conditions_of_financing: 'Your suspensive conditions of financing',
  amount_for_which_funding: 'Amount for which funding is requested',
  period_within_credit_application:
    'Period within which your credit application is approved',
  instellen: 'Set up',
  modal_service_cost_message:
    'This amount is added to your bid to calculate the registration fees (sales rights).',
  our_advice_page: 'our advice page',
  about_registration_fees: 'about registration fees.',
  fieldset_legend_buyer: 'Note: you buy',
  together: 'together',
  buyer_company_details_message:
    'with your company, there are 2 buyers and you must add both yourself and the company (with the corresponding representative (s)) separately as a buyer.',
  you_need_to_accept_this: 'You need to accept this.',
  legally_authorised_to_represent: 'I can legally represent this company',
  name_of_company: 'Name of the company',
  corporate_form: 'Corporate form',
  select_company_type: 'Select the company type',
  ondernemingsnummer: 'Company number',
  company_is_subject_to_vat: 'This company is subject to VAT',
  advisor: 'Advisor',
  nummer: 'Number',
  toevoeging: 'Addition',
  placeholder_autocomplete: 'Search using zip code or city name',
  fieldset_legend_company: 'Represented by:',
  mobile_number_use_message:
    'Your mobile number will soon be used to sign the purchase promise via text message',
  national_insurance_number: 'National Insurance number',
  birthplace: 'Birthplace',
  mail_me: 'Mail me',
  show_less: 'Show less',
  show_more: 'Show more',
  error_translations: 'Failed to fetch translations!',
  days: 'days',
  new_version: 'New version',
  is_available: 'is available',
  customer_portal_updated: 'Customer portal is updated!',
  update: 'Update',
  bid_message_pending:
    'The sales notes are currently being prepared. Please be patient ...',
  bid_message_success:
    'Everyone who participates in the purchase has now received a purchase promise in their mailbox. Once everyone has signed it, your bid has been placed and will be presented to the seller.',
  validation_required_terms: 'You need to accept the terms and conditions.',
  validation_required_buyers:
    'You have not added any buyers, please add one or more buyers.',
  please_accept_terms: 'Please accept the terms to submit.',
  error_bid_not_placed: 'could not place the bid.',
  no_description_available: 'No description available.',
  construction_year: 'Construction year',
  buildings: 'Construction',
  ground_surface: 'Ground surface',
  bedrooms: 'Bedrooms',
  bathrooms: 'Bathrooms',
  garden: 'Garden',
  terrace: 'Terrace',
  living_area: 'habitable surface',
  your_visit_moment: 'Your visit moment',
  visit_enter_details_message:
    'The chosen visit time is still free. We only need a few more details and we will let the seller know that you are coming.',
  confirm_appointment: 'Confirm appointment',
  suitable_time_not_found: "Didn't find a suitable time?",
  make_personal_appointment:
    'Make a personal appointment with the seller using the form below.',
  what_is_suitable_time: 'Which time is right for you?',
  send_proposal: 'Send proposal',
  message_received: 'we have received your message!',
  book_another_visit: 'Book another visit',
  visit_booked: 'Visit moment confirmed!',
  visit_booking_confirmed:
    'Good news! The moment of visit has been confirmed. You will receive all the details by email and below you will find a short summary.',
  add_to_your_own_calendar: 'Add to your own calendar',
  back_to: 'Back to',
  success_details_submit: 'Details have been submitted successfully.',
  user_pass_incorrect: 'Username/password is incorrect',
  close: 'Close',
  page_not_found: 'Page not found',
  choose_visit_time: 'Choose visit time',
  no_timeslots_available: 'No time slots available',
  please_select_circled_dates: 'please select one of the circled dates',
  bid_finance_min_value:
    'The value of bid should be greater than 1 euro for finance.',
  bid_offer_validity: 'Your offer is valid for 10 days',
  service_costs_are: 'The service costs are',
  modal_total_cost_message:
    'The bid you make always excludes sales taxes. In the event that you ultimately purchase the property, you will have to pay either sales taxes (registration duties) or VAT on top of your bid and auction fee, depending on which system applies.',
  day_0: 'Sunday',
  day_1: 'Monday',
  day_2: 'Tuesday',
  day_3: 'Wednesday',
  day_4: 'Thursday',
  day_5: 'Friday',
  day_6: 'Saturday',
  buyer_locale_help_text:
    'This will be the language used for communicating with the buyer.',
  message_five_min_difference:
    'Start and end time should have atleast 5 min difference.',
  form_not_valid: 'Please fill the required fields correctly.',
  valid_time: 'Please select a future time.',
  placeholder_select_interval: 'Select interval',
  label_interval: 'Divide into reservations from',
  option_no_interval: 'No interval',
  label_datetime_end: 'End time',
  label_datetime_start: 'Start time',
  add_reservation_block: 'Add reservation block',
  there_are: 'There are',
  appointment_blocks: 'appointment blocks',
  minutes: 'minutes',
  created: 'created',
  see_the_slots_to_be_created: 'See the slots that will be created',
  new_slot_added_singular: 'New slots have been added',
  new_slot_added_plural: 'New slot has been added',
  session_expired: 'Your session has expired',
  password_show: 'Show password',
  password_hide: 'Hide password',
  company_that_wants_to_buy: 'Company that wants to buy',
  represented_by: 'Represented by',
  buyer_updated: 'has been updated',
  buyer_added: 'has been added as buyer',
  visit_reservation_time: 'Reservation viewing time',
  booking: 'Booking',
  not_yet_reserved: 'Not yet reserved',
  remove_timeslot: 'Remove timeslot',
  visit_reserved: 'Viewing moment reserved',
  has_been_removed: 'has been removed',
  property_detail: 'General data',
  property_visit: 'Visit arrangement',
  could_not_authenticate: 'Could not authenticate user',
  already_authenticated: 'You are already logged in, taking you to Dashboard',
  user_not_authorized: 'User is not authorized',
  user_not_authenticated: 'User is not authenticated',
  internal_server_error: 'Internal server error',
  bid_snackbar_success: 'Bid has been placed successfully',
  could_not_place_bid: 'Something went wrong, could not place the bid',
  description: 'Description',
  please_enter_valid: 'Please enter a valid',
  CANDIDATE_ALREADY_ON_WAITLIST: 'You are already on the waiting list',
  message_added_to_waitlist: 'You have been added to the waiting list',
  success_conditions_message_bold: 'Conditions precedent',
  success_conditions_message: 'set successfully',
  log_out: 'Log out',
  modal_message_no_buyers: 'No buyer (s) added yet',
  label_company_toggle: 'I buy with a company',
  location: 'Location',
  select_language: 'Select language',
  photo: 'Photo',
  property_img: 'Property image',
  max_bookings:
    'Determine how many simultaneous visits can take place on this time slot',
  from: 'from',
  join_waitlist: 'Join waiting list',
  buyer_mobile_help:
    'Your mobile number will used to sign the purchase promise via text message',
  message: 'Your message',
  company_type_options: {
    0: 'private company',
    1: 'limited company',
    2: 'limited partnership',
    3: 'partnership',
    4: 'limited partnership',
    5: 'partnership',
    6: 'limited company',
    7: 'cooperative company with limited liability',
    8: 'partnership Limited by shares',
    9: 'cooperative company with unlimited liability',
    10: 'one-person bvba',
    11: 'starters bvba',
    12: 'silent partnership',
    13: 'temporary partnership',
    14: 'agricultural company',
    15: 'economic partnership'
  },
  bid_pre_submit_message:
    'When you click on this button you will receive a link to the purchase promise in your mailbox, which you can read through and sign if you agree. If you do not receive the e-mail, also check your spam.',
  condition_modal_text:
    'A condition precedent of financing means that you put a clause in your compromise stating that if the buyer does not get credit from 3 different lending institutions, he or she can abandon the sale.',
  SLOT_DOES_NOT_EXIST: '',
  SLOT_IS_FULL: '',
  SLOT_DATA_IS_INVALID: '',
  request_timeout: 'Request time-out exceeded',
  clear: 'Clear',
  error_property_details: 'Error fetching property details',
  gdpr_1:
    'By completing the optional and/or mandatory fields, you give permission to process your personal data as described in our',
  gdpr_2: 'Consult our',
  gdpr_3: 'here for more information.'
};
