export const fr = {
  email_label: 'Adresse électronique',
  password_label: 'Mot de passe',
  required: 'obligé',
  password_strength_validation:
    'Le mot de passe doit contenir au moins un caractère numérique, un caractère spécial, une lettre majuscule et un caractère normal.',
  login: "S'inscrire",
  request_reset_link: 'Envoyer le lien de réinitialisation',
  request_reset_link_copy:
    'Entrez votre adresse e-mail ci-dessous et nous vous enverrons immédiatement un lien pour réinitialiser votre mot de passe.',
  reset_link_sent_copy:
    "Nous avons envoyé un lien de réinitialisation à l'adresse e-mail que vous avez saisie. Utilisez le lien dans l'e-mail pour réinitialiser votre mot de passe.",
  reset_link_send_again: 'Renvoyer le lien de réinitialisation',
  new_password: 'Votre nouveau mot de passe',
  confirm_new_password: 'Répétez votre nouveau mot de passe',
  set_password: 'Définir le mot de passe',
  reset_password_success_copy: 'Votre mot de passe a été changé avec succès!',
  return_to_login: 'Revenir pour vous inscrire',
  personal_information: 'Informations personnelles',
  first_name: 'Prénom',
  last_name: 'Nom de famille',
  street: 'Rue',
  number: 'Numéro de maison',
  box: 'Bus non',
  city: 'Endroit',
  phone: 'Numéro de téléphone',
  mobile: 'Numéro de téléphone portable',
  birth_date: 'Date de naissance',
  preferred_language: 'Langue préférée',
  reset_your_password: 'Réinitialisez votre mot de passe',
  by_using_our_customer_portal:
    'En utilisant notre portail client, vous acceptez le',
  terms_of_use: "conditions d'utilisation",
  and_the: 'et le',
  privacy_policy: 'politique de confidentialité',
  save: 'sauvegarder',
  no_buildings_yet: 'Pas encore de bâtiments',
  create_new_search: 'Créer une nouvelle recherche',
  set_preferences_for_newsletters:
    'Définir les préférences pour les newsletters',
  adjust_your_data: 'Ajustez vos données',
  houses: 'Maisons',
  my_profile: 'Mon profil',
  my_newsletters: 'Mes newsletters',
  my_searches: 'Mes recherches',
  make_an_offer: 'Faire une offre',
  how_much_do_you_want_to_offer: 'Combien souhaitez-vous offrir?',
  your_offer: 'Ton offre',
  service_fee: 'Frais de service',
  total_cost: 'Coût total',
  need_help: "Besoin d'aide?",
  what_is_subject_of_message: 'Quel est le sujet de votre message?',
  send_message: 'Pour envoyer un message',
  i_wish_to_make_an_offer_with: 'Je souhaite faire une offre avec',
  conditions_financing: 'conditions préalables au financement',
  who_is_the_buyer: "Qui est l'acheteur?",
  add_buyer: 'Ajouter un acheteur',
  buy_with_company: "J'achète avec une entreprise",
  submit_offer: 'Soumettre une offre',
  i_agree_with_the: "Je suis d'accord avec le",
  purchase_conditions: "conditions d'achat",
  logout_success: 'Déconnexion réussie',
  success: 'Succès',
  you_have_been_logged_out: 'Vous avez été déconnecté',
  details_submitted_successfully: 'Les détails ont été envoyés avec succès',
  form_submitted: 'Formulaire envoyé',
  login_success: 'Inscription réussie',
  failed_to_fetch_profile_data:
    'Échec de la récupération des informations de profil',
  failed_to_fetch_property_data:
    'Échec de la récupération des informations de profil',
  something_went_wrong: 'Un problème est survenu, veuillez réessayer',
  user: 'Utilisateur',
  welcome: 'Bienvenue',
  this_field: 'Ce champ',
  required_fields_missing: 'Les champs obligatoires sont manquants',
  our_advice_page_link: 'https://dewaele.com/een-bod-uitvoeren',
  privacy_policy_property_link: 'https://www.dewaele.com/fr/privacy-policy',
  loading_cities: 'Chargement des villes',
  no_cities_found: 'Désolé, aucune ville trouvée.',
  dashboard_properties_text:
    'Vous trouverez ici un aperçu de toutes les propriétés que vous avez gérées chez Dewaele. Cet aperçu montre également les propriétés qui correspondent à vos critères de recherche',
  suspensive_conditions_of_financing:
    'Vos conditions de financement suspensives',
  amount_for_which_funding: 'Montant pour lequel un financement est demandé',
  period_within_credit_application:
    'Période pendant laquelle votre demande de crédit est approuvée',
  instellen: 'Régler',
  modal_service_cost_message:
    "Ce montant s'ajoute à votre enchère pour calculer les frais d'inscription (droits de vente).",
  our_advice_page: 'notre page de conseils',
  about_registration_fees: "sur les frais d'inscription.",
  fieldset_legend_buyer: 'Remarque: vous achetez',
  together: 'ensemble',
  buyer_company_details_message:
    "avec votre entreprise, il y a 2 acheteurs et vous devez ajouter vous-même et l'entreprise (avec le (s) représentant (s) correspondant) séparément en tant qu'acheteur.",
  you_need_to_accept_this: 'Vous devez accepter cela.',
  legally_authorised_to_represent:
    'Je peux représenter légalement cette entreprise',
  name_of_company: "Nom de l'entreprise",
  corporate_form: 'Forme corporative',
  select_company_type: 'Sélectionnez le type de société',
  ondernemingsnummer: "Numéro d'entreprise",
  company_is_subject_to_vat: 'Cette société est soumise à la TVA',
  advisor: 'Conseiller',
  nummer: 'Numéro',
  toevoeging: 'Addition',
  placeholder_autocomplete:
    "Rechercher à l'aide du code postal ou du nom de la ville",
  fieldset_legend_company: 'Représenté par:',
  mobile_number_use_message:
    "Votre numéro de mobile sera bientôt utilisé pour signer la promesse d'achat par SMS",
  national_insurance_number: "numéro d'assurance nationale",
  birthplace: 'Lieu de naissance',
  mail_me: 'Envoyez moi un email',
  show_less: 'Montrer moins',
  show_more: 'Montrer plus',
  error_translations: 'Échec de la récupération des traductions!',
  days: 'jours',
  new_version: 'Nouvelle version',
  is_available: 'est disponible',
  customer_portal_updated: 'Le portail client est mis à jour!',
  update: 'Mise à jour',
  bid_message_pending:
    'Les notes de vente sont en cours de préparation. Veuillez être patient ...',
  bid_message_success:
    "Tous les acheteurs qui achètent ont maintenant reçu une promesse d'achat dans leur boîte aux lettres. Une fois que tout le monde l'a signé, votre offre a été placée et sera présentée au vendeur.",
  validation_required_terms: 'Vous devez accepter les termes et conditions.',
  validation_required_buyers:
    "Vous n'avez ajouté aucun acheteur, veuillez ajouter un ou plusieurs acheteurs.",
  please_accept_terms: 'Veuillez accepter les conditions pour soumettre.',
  error_bid_not_placed: "n'a pas pu placer l'offre.",
  no_description_available: 'Pas de description disponible.',
  construction_year: 'Année de construction',
  buildings: 'Construction',
  ground_surface: 'Surface de sol',
  bedrooms: 'Chambres a coucher',
  bathrooms: 'Salles de bain',
  garden: 'Jardin',
  terrace: 'Terrasse',
  living_area: 'Surface habitable',
  your_visit_moment: 'Votre moment de visite',
  visit_enter_details_message:
    "L'heure de visite choisie est toujours gratuite. Nous n'avons besoin que de quelques détails supplémentaires et nous informerons le vendeur de votre venue.",
  confirm_appointment: 'Confirmer le rendez-vous',
  suitable_time_not_found: "Vous n'avez pas trouvé de moment approprié?",
  make_personal_appointment:
    'Prenez rendez-vous personnel avec le vendeur en utilisant le formulaire ci-dessous.',
  what_is_suitable_time: 'Quel moment vous convient le mieux?',
  send_proposal: 'Envoyer une proposition',
  message_received: 'Nous avons reçu votre message!',
  book_another_visit: 'Réservez une autre visite',
  visit_booked: 'Moment de visite confirmé!',
  visit_booking_confirmed:
    'Bonnes nouvelles! Le moment de la visite a été confirmé. Vous recevrez tous les détails par e-mail et ci-dessous vous trouverez un bref résumé.',
  add_to_your_own_calendar: 'Ajouter à votre propre calendrier',
  back_to: 'Retour à',
  success_details_submit: 'Les détails ont été soumis avec succès.',
  user_pass_incorrect: "Le nom d'utilisateur / mot de passe est incorrect",
  close: 'Fermer',
  page_not_found: 'Page non trouvée',
  choose_visit_time: "Choisissez l'heure de la visite",
  no_timeslots_available: 'Aucune plage horaire disponible',
  please_select_circled_dates:
    "veuillez sélectionner l'une des dates encerclées",
  bid_finance_min_value:
    "La valeur de l'offre doit être supérieure à 1 euro pour le financement.",
  bid_offer_validity: 'Votre offre est valable 10 jours',
  service_costs_are: 'Les frais de service sont',
  modal_total_cost_message:
    "L'offre que vous faites exclut toujours les taxes de vente. Si vous achetez finalement le bien, vous devrez payer soit des taxes de vente (droits d'enregistrement), soit la TVA en plus de votre enchère et des frais de vente aux enchères, selon le système applicable.",
  day_0: 'Dimanche',
  day_1: 'Lundi',
  day_2: 'Mardi',
  day_3: 'Mercredi',
  day_4: 'Jeudi',
  day_5: 'Vendredi',
  day_6: 'Samedi',
  buyer_locale_help_text:
    "Ce sera la langue utilisée pour communiquer avec l'acheteur.",
  message_five_min_difference:
    'Les heures de début et de fin doivent avoir au moins 5 minutes de différence.',
  form_not_valid: 'Veuillez remplir correctement les champs obligatoires.',
  valid_time: 'Veuillez sélectionner une heure future.',
  placeholder_select_interval: "Sélectionnez l'intervalle",
  label_interval: 'Divisez en réservations de',
  option_no_interval: "Pas d'intervalle",
  label_datetime_end: 'Heure de fin',
  label_datetime_start: 'Heure de début',
  add_reservation_block: 'Ajouter un bloc de réservation',
  there_are: 'Il y a',
  appointment_blocks: 'blocs de rendez-vous',
  minutes: 'minutes',
  created: 'créé',
  see_the_slots_to_be_created: 'Voir les slots qui seront créés',
  new_slot_added_singular: 'De nouveaux emplacements ont été ajoutés',
  new_slot_added_plural: 'Un nouvel emplacement a été ajouté',
  session_expired: 'Votre session a expiré',
  password_show: 'Montrer le mot de passe',
  password_hide: 'Masquer le mot de passe',
  company_that_wants_to_buy: 'Entreprise qui veut acheter',
  represented_by: 'Représenté par',
  buyer_updated: 'a été mis à jour',
  buyer_added: 'a été ajouté comme acheteur',
  visit_reservation_time: 'Heure de visionnage de la réservation',
  booking: 'Réservation',
  not_yet_reserved: 'Pas encore réservé',
  remove_timeslot: 'Supprimer le créneau horaire',
  visit_reserved: 'Moment de visionnage réservé',
  has_been_removed: 'a été retiré',
  property_detail: 'Données générales',
  property_visit: 'Arrangement de visite',
  could_not_authenticate: "Impossible d'authentifier l'utilisateur",
  already_authenticated:
    'Vous êtes déjà connecté, vous amenant au tableau de bord',
  user_not_authorized: 'Utilisateur non autorisé',
  user_not_authenticated: "L'utilisateur n'est pas authentifié",
  internal_server_error: 'Erreur Interne du Serveur',
  bid_snackbar_success: 'Bod is succesvol geplaatst',
  could_not_place_bid:
    "Une erreur s'est produite, impossible de placer l'enchère",
  description: 'description',
  please_enter_valid: 'Please enter a valid',
  CANDIDATE_ALREADY_ON_WAITLIST: "Vous êtes déjà sur la liste d'attente",
  message_added_to_waitlist: "Vous avez été ajouté à la liste d'attente",
  success_conditions_message_bold: 'Conditions préalables',
  success_conditions_message: 'réglé avec succès',
  log_out: 'Se déconnecter',
  modal_message_no_buyers: 'Aucun acheteur ajouté pour le moment',
  label_company_toggle: "J'achète avec une entreprise",
  location: 'Emplacement',
  select_language: 'Choisir la langue',
  photo: 'Photo',
  property_img: 'Image de la propriété',
  max_bookings:
    'Déterminez le nombre de visites simultanées qui peuvent avoir lieu sur ce créneau horaire',
  from: 'de',
  join_waitlist: "Rejoindre la liste d'attente",
  buyer_mobile_help:
    "Votre numéro de mobile est utilisé pour signer la promesse d'achat par SMS",
  message: 'Votre message',
  company_type_options: {
    0: 'entreprise privée',
    1: 'société anonyme',
    2: 'Partenariat limite',
    3: 'Partenariat',
    4: 'Partenariat limite',
    5: 'Partenariat',
    6: 'société anonyme',
    7: 'société coopérative à responsabilité limitée',
    8: 'société en commandite par actions',
    9: 'société coopérative à responsabilité illimitée',
    10: 'bvba une personne',
    11: 'démarreurs bvbas',
    12: 'partenariat silencieux',
    13: 'partenariat temporaire',
    14: 'entreprise agricole',
    15: 'partenariat économique'
  },
  bid_pre_submit_message:
    "Lorsque vous cliquez sur ce bouton, vous recevez un lien vers la promesse d'achat dans votre boîte aux lettres, que vous pouvez lire et signer si vous acceptez. Si vous ne recevez pas l'e-mail, vérifiez également votre spam.",
  condition_modal_text:
    "Une condition suspensive de financement signifie que vous mettez dans votre compromis une clause stipulant que si l'acheteur n'obtient pas de crédit auprès de 3 établissements de crédit différents, il peut renoncer à la vente.",
  SLOT_DOES_NOT_EXIST: '',
  SLOT_IS_FULL: '',
  SLOT_DATA_IS_INVALID: '',
  request_timeout: '',
  error_property_details: '',
  gdpr_1:
    'En remplissant les champs facultatifs et/ou obligatoires, vous autorisez le traitement de vos données personnelles comme décrit dans notre',
  gdpr_2: 'Consultez notre',
  gdpr_3: 'ici pour plus d’informations.'
};
